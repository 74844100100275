import React from 'react';
import Footer from '../components/Footer';
import Layout from '../components/layout';

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isArticleVisible: true,
      timeout: false,
      articleTimeout: false,
      article: 'contact-us',
      showResult: false
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    this.timerID1 = setTimeout(() => this.timeout(), 325);
    this.timerID2 = setTimeout(() => this.articleTimeout(), 350);
  }

  componentWillUnmount() {
    clearTimeout(this.timerID);
  }

  timeout() {
    this.setState({
      timeout: !this.state.timeout
    });
  }

  articleTimeout() {
    this.setState({
      articleTimeout: !this.state.articleTimeout
    });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleSubmit = () => {
    this.setState({
      showResult: true
    });
  };

  handleReset = () => {
    this.setState({
      showResult: false
    });
  };

  render() {
    return (
      <Layout location={this.props.location}>
        <div>
          <div id="wrapper" className="reduceSize">
            <article
              id="contact-us"
              className={`${'active'} ${
                this.props.articleTimeout ? 'timeout' : ''
              }`}
            >
              <h2 className="major">Let's connect</h2>
              <form
                method="post"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="contact"
                action="/thank-you"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <div className="field">
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name" id="name" required />
                </div>
                <div className="field">
                  <label htmlFor="email">Email</label>
                  <input type="email" name="email" id="email" required />
                </div>
                <div className="field">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    style={{ height: '44px' }}
                  />
                </div>
                <div className="field">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    rows="4"
                    required
                  ></textarea>
                </div>
                <ul className="actions">
                  <li>
                    <input
                      type="submit"
                      value="Send Message"
                      className="special"
                      disabled={this.state.showResult ? true : false}
                    />
                  </li>
                  <li>
                    <input
                      type="reset"
                      value="Reset"
                      onClick={this.handleReset}
                    />
                  </li>
                </ul>
              </form>
              <div
                style={
                  this.state.showResult ? { display: '' } : { display: 'none' }
                }
              >
                Thank you for your message. We will reach out as soon as
                possible
              </div>
            </article>

            <Footer />
          </div>
          <div id="bg"></div>
        </div>
      </Layout>
    );
  }
}

export default ContactUsPage;
